<template>
  <div class="DataAnalysisResult">
    <div class="bgc">
      <img :src="require('assets/data.gif')" alt="" />
    </div>
    <div class="data-analysis-history-header">
      <div class="back_box">
        <span class="back" @click="goBack"
          ><img
            :src="require('assets/images/goldenSentence/back.png')"
            alt=""
          />返回</span
        >

        <div
          class="History"
          @click="
            $router.push({
              name: 'DataAnalysisHistory',
            })
          "
        >
          <img
            :src="require('assets/images/goldenSentence/History.png')"
            alt=""
          />
          历史报告
        </div>
      </div>
    </div>

    <!-- <div class="dataAnalysisItem" v-if="!isShow">
      <div class="tltle">1. 词频及关联数据分析</div>
      <div class="rigth">
        <div class="price">按文档字符计费</div>

        <div class="kind">
          <i class="icon"></i>
          <span class="txt">分析报告样本</span>
        </div>
      </div>
    </div> -->

    <div class="Result_box" v-if="!isShow">
      <div class="plan_text">
        <div class="text">
          <div class="round"></div>
          {{ percentage != 100 ? "数据分析中..." : "数据分析完成." }}
        </div>

        <div class="plan_sum">{{ percentage }}%</div>
      </div>

      <div class="progress">
        <el-progress :percentage="percentage" :format="format"></el-progress>
      </div>

      <!-- 分析 -->
      <div class="prompt_text" v-if="percentage == 100">
        <div class="img"></div>

        <span> 数据分析报告已经完成，请点击 </span>
        "
        <div class="line_block">
          <div
            class="history"
            @click="
              $router.push({
                name: 'DataAnalysisHistory',
              })
            "
          >
            <img
              :src="require('assets/images/goldenSentence/History-blue.png')"
              alt=""
            />
            历史报告
          </div>
        </div>
        "下载查看。
      </div>

      <div v-else class="prompt_text">
        <div class="img"></div>

        <span>
          数据处理量大的耗时将比较长，您不需要值守。数据分析完成之后，数据分析报告将自动存入历史报告当中，可点击本界面的右上角
        </span>
        "
        <div class="line_block">
          <div
            class="history"
            @click="
              $router.push({
                name: 'DataAnalysisHistory',
              })
            "
          >
            <img
              :src="require('assets/images/goldenSentence/History-blue.png')"
              alt=""
            />
            历史报告
          </div>
        </div>
        "下载查看。
      </div>
    </div>

    <div class="none" v-if="isShow">
      <img :src="require('assets/images/no-shop.png')" alt="" />
      <div class="text">无数据分析</div>
    </div>

    <!-- 提示 -->
    <div class="prompt" v-if="percentage == 100">
      <img :src="require('assets/images/chenggong.png')" alt="" />
      数据分析报告已经完成，请点击"
      <div
        class="history"
        @click="
          $router.push({
            name: 'DataAnalysisHistory',
          })
        "
      >
        <img
          :src="require('assets/images/goldenSentence/History-blue.png')"
          alt=""
        />
        历史报告
      </div>
      "下载查看。
    </div>
  </div>
</template>

<script>
import { AnalysisIsfinish } from "api/infoCompile";
export default {
  name: "DataAnalysisResult",
  data() {
    return {
      // 进度
      percentage: 0,

      //定时器
      timer: null,

      // 是否显示
      isShow: false,

      // 检查
      checkTimer: null,
    };
  },
  methods: {
    checkTimerfun(id) {
      let _this = this;
      this.checkTimer = window.setInterval(() => {
        _this.analysisIsfinish(id);
      }, 5000);
    },

    //返回
    goBack() {
      this.$router.go(-1);
    },

    format(percentage) {
      return percentage === 100 ? "" : "";
    },

    timerFunction() {
      let that = this;
      this.timer = window.setInterval(() => {
        if (that.percentage > 82) {
          return window.clearInterval(that.timer);
        }
        that.percentage++;
      }, 100);
    },
    //  // 获取历史报告
    //     async getSeportlist(analysisId) {
    //       let form = new FormData();
    //       form.append("analysisId", analysisId);

    //       const res = await AnalysisReport(form);
    //       if (res.code == 200) {
    //         this.analysisIsfinish(analysisId);
    //       }

    //     },
    // 是否完成
    async analysisIsfinish(analysisId) {
      // const data = {
      //   analysisId,
      // };
      let _this = this;
      let form = new FormData();
      form.append("analysisId", analysisId ? analysisId : "");

      const res = await AnalysisIsfinish(form);
      // console.log(res);
      if (res.code == 200) {
        if (res.data) {
          if (res.data.isFinish == 2) {
            this.percentage = 100;
            window.clearInterval(_this.checkTimer);
            this.$router.push({
              name: "DataAnalysisHistory",
            });
          }
        } else {
          this.isShow = true;
          window.clearInterval(_this.checkTimer);
        }
      }
    },
  },

  created() {
    this.analysisIsfinish(this.$route.query.id);
    this.percentage = Number(window.sessionStorage.getItem("percentage"))
      ? Number(window.sessionStorage.getItem("percentage")) > 80
        ? Number(window.sessionStorage.getItem("percentage"))
        : Number(window.sessionStorage.getItem("percentage")) + 6
      : 0;
    this.timerFunction();
    this.checkTimerfun(this.$route.query.id);
  },
  beforeDestroy() {
    //页面销毁前，清除定时器
    window.clearInterval(this.timer); //清除timer定时器
    window.clearInterval(this.checkTimer); //清除timer定时器
    // 记录跳转后的进度

    window.sessionStorage.setItem(
      "percentage",
      this.percentage == 100 ? 0 : this.percentage
    );
  },
};
</script>

<style lang="scss" scoped>
.DataAnalysisResult {
  .bgc {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    z-index: -1;
    img {
      width: 100%;
    }
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
  position: relative;
  min-height: 500px;
  width: 100%;
  .data-analysis-history-header {
    box-sizing: border-box;
    margin-top: 14px;

    .back_box {
      display: flex;
      justify-content: space-between;
    }

    .back {
      @include noselect;
      img {
        width: 6px;
        height: 9px;
        margin-right: 6px;
        vertical-align: middle;
      }
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      cursor: pointer;
    }

    .History {
      cursor: pointer;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 5px;
        width: 13px;
        height: 13px;
      }
    }
  }

  .dataAnalysisItem {
    border-bottom: 1px solid #f7f7f7;
    padding: 20px 0;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    .tltle {
      width: 50%;
      color: #4587ff;
    }

    .rigth {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .kind {
        display: flex;
        align-items: center;
        color: #4587ff;
        .icon {
          width: 16px;
          height: 16px;
          background: url("~assets/images/goldenSentence/analysis-blue.png")
            100% no-repeat;
          margin-right: 5px;
        }
      }
    }
  }

  .Result_box {
    width: 410px;
    margin: 80px auto 0;
    .plan_text {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin-bottom: 10px;
      .text {
        display: flex;
        align-items: center;
        .round {
          margin-right: 10px;
          width: 10px;
          height: 10px;
          border: 2px solid #4898fc;
          border-radius: 50%;
        }
      }

      .plan_sum {
        color: #4587ff;
      }
    }

    .progress {
      margin-bottom: 20px;
      .el-progress {
        ::v-deep .el-progress-bar {
          padding-right: 0;

          .el-progress-bar__outer {
            height: 10px !important;
            background-color: #c9e0ff;
          }
        }
      }
    }

    .prompt_text {
      font-size: 12px;
      line-height: 2;
      .img {
        vertical-align: middle;
        display: inline-block;
        width: 12px;
        height: 12px;
        @include backgroundGroup("~assets/images/disk/Icons/006.png");
      }

      .line_block {
        line-height: 2;
        display: inline-block;
        vertical-align: bottom;
        .history {
          display: flex;
          align-items: center;
          color: #4587ff;
          cursor: pointer;
          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .none {
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 15px;
    color: #ccc;
    img {
      margin-bottom: 20px;
    }
  }

  .prompt {
    // margin-top: 20px;
    border: 1px solid #4587ff;
    border-radius: 22px;
    padding: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    width: 435px;
    margin: 50px auto 0;
    img {
      margin-right: 5px;
      width: 21px;
      height: 21px;
    }
    .history {
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        margin: 0 5px;
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
