var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DataAnalysisResult"},[_c('div',{staticClass:"bgc"},[_c('img',{attrs:{"src":require('assets/data.gif'),"alt":""}})]),_c('div',{staticClass:"data-analysis-history-header"},[_c('div',{staticClass:"back_box"},[_c('span',{staticClass:"back",on:{"click":_vm.goBack}},[_c('img',{attrs:{"src":require('assets/images/goldenSentence/back.png'),"alt":""}}),_vm._v("返回")]),_c('div',{staticClass:"History",on:{"click":function($event){return _vm.$router.push({
            name: 'DataAnalysisHistory',
          })}}},[_c('img',{attrs:{"src":require('assets/images/goldenSentence/History.png'),"alt":""}}),_vm._v(" 历史报告 ")])])]),(!_vm.isShow)?_c('div',{staticClass:"Result_box"},[_c('div',{staticClass:"plan_text"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"round"}),_vm._v(" "+_vm._s(_vm.percentage != 100 ? "数据分析中..." : "数据分析完成.")+" ")]),_c('div',{staticClass:"plan_sum"},[_vm._v(_vm._s(_vm.percentage)+"%")])]),_c('div',{staticClass:"progress"},[_c('el-progress',{attrs:{"percentage":_vm.percentage,"format":_vm.format}})],1),(_vm.percentage == 100)?_c('div',{staticClass:"prompt_text"},[_c('div',{staticClass:"img"}),_c('span',[_vm._v(" 数据分析报告已经完成，请点击 ")]),_vm._v(" \" "),_c('div',{staticClass:"line_block"},[_c('div',{staticClass:"history",on:{"click":function($event){return _vm.$router.push({
              name: 'DataAnalysisHistory',
            })}}},[_c('img',{attrs:{"src":require('assets/images/goldenSentence/History-blue.png'),"alt":""}}),_vm._v(" 历史报告 ")])]),_vm._v(" \"下载查看。 ")]):_c('div',{staticClass:"prompt_text"},[_c('div',{staticClass:"img"}),_c('span',[_vm._v(" 数据处理量大的耗时将比较长，您不需要值守。数据分析完成之后，数据分析报告将自动存入历史报告当中，可点击本界面的右上角 ")]),_vm._v(" \" "),_c('div',{staticClass:"line_block"},[_c('div',{staticClass:"history",on:{"click":function($event){return _vm.$router.push({
              name: 'DataAnalysisHistory',
            })}}},[_c('img',{attrs:{"src":require('assets/images/goldenSentence/History-blue.png'),"alt":""}}),_vm._v(" 历史报告 ")])]),_vm._v(" \"下载查看。 ")])]):_vm._e(),(_vm.isShow)?_c('div',{staticClass:"none"},[_c('img',{attrs:{"src":require('assets/images/no-shop.png'),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("无数据分析")])]):_vm._e(),(_vm.percentage == 100)?_c('div',{staticClass:"prompt"},[_c('img',{attrs:{"src":require('assets/images/chenggong.png'),"alt":""}}),_vm._v(" 数据分析报告已经完成，请点击\" "),_c('div',{staticClass:"history",on:{"click":function($event){return _vm.$router.push({
          name: 'DataAnalysisHistory',
        })}}},[_c('img',{attrs:{"src":require('assets/images/goldenSentence/History-blue.png'),"alt":""}}),_vm._v(" 历史报告 ")]),_vm._v(" \"下载查看。 ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }